import React from 'react'
import styled from 'styled-components'
import { defaultTheme, PrimaryButton } from '../styles'

const SidebarPhoneCallout = () => {
    return (
        <PhoneCallout>
            <h2>Call Us Now</h2>
            <p>For confidential, no-cost case evaluation</p>
            <PrimaryButton href="tel:4243430660" modifiers={['large', 'green']}>
                (424) 343-0660
            </PrimaryButton>
        </PhoneCallout>
    )
}

export default SidebarPhoneCallout

const PhoneCallout = styled.div`
    margin-bottom: 40px;
    padding: 40px 28px;
    background: #89a5cf;
    border-top: 6px solid ${defaultTheme.green};

    h2 {
        margin-bottom: 12px;
        font-size: 28px;
        font-weight: 500;
    }

    p {
        margin: 12px 0 32px;
    }

    a {
        display: inline-block;
    }
`;